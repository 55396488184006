import { Link } from 'react-router-dom';

import { SocialLinks } from '../SocialLinks';

import './Footer.css';


export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-center">
        © {new Date().getFullYear()} - KiffePaws Educ - &nbsp;
        <Link to="/legalnotice">Mentions légales</Link>
      </div>
      <SocialLinks />
    </div>
  );
}