import './Review.css';

import { IMAGE_WIDTH } from '../../constants';

interface ReviewProps {
  lines: string[],
  author: string,
  index: number,
  photo?: string
}

export const Review: React.FC<ReviewProps> = ({ lines, author, index, photo }) => {
  const isEven = index % 2 === 0; // Determine if the review should be left-aligned or right-aligned

  return (
    <div className={`review ${isEven ? 'left' : 'right'}`}>
      <p className="review-text"> {lines.map((line, i) => (
        <p key={i} className="review-line">{line}</p>
      ))}
      </p>
      <p className="review-author">- {author}</p>
      {photo && <img src={photo} alt={author} width={IMAGE_WIDTH + "px"} className="review-author" />}
    </div>
  );
};