import React from 'react';
import { GlassBlock } from './GlassBlock';

interface GlassBlockTextProps {
  content: string;
}

export const GlassBlockText: React.FC<GlassBlockTextProps> = ({ content }) => {
  return (
    <GlassBlock>
      {content}
    </GlassBlock>
  );
};