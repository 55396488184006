import './Card.css';

import { IMAGE_WIDTH } from '../../constants';

interface CardProps {
  name: string;
  title?: string;
  image?: string;
  description?: string;
}

export const Card: React.FC<CardProps> = ({ name, title, image, description }) => {
  return (
    <div className="App-card">
      <figure className="image-figure">
        <img src={image} alt={title || name} width={IMAGE_WIDTH + "px"} />
        <figcaption>{name}</figcaption>
      </figure>
      {description && <p>{description}</p>}
    </div>
  );
}
