import { ContactMe } from './ContactMe';

import '../App.css';


export const Intro = () => {
  const highlights: string[] = [
    "Education et rééducation canine à domicile et en extérieur",
    "Cours individuels et cours collectifs",
    "Méthodes positives non permissives",
  ]
  return (
    <div className="App-intro">
      <h1>KiffePaws Educ - Educateur comportementaliste canin dans les Yvelines (78) et Hauts-de-Seine (92)</h1>
      <ContactMe />
      {highlights.map(highlight => <h2>{highlight}</h2>)}
      <h3>J'aide les heureux parents de chiens et ceux à en devenir à avoir une vie fun et sereine avec leur meilleur ami, quelque soit son âge, sa race, son passé, son caractère, en moins de 3 mois</h3>
    </div>
  );
}