import '../App.css';

import { Button } from './Button';

import { PHONE_NUMBER, EMAIL_ADDRESS } from '../constants';

export const ContactMe = () => {
  const getAppropriateContact: () => string = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      return `tel:${PHONE_NUMBER}`;
    }
    return `mailto:${EMAIL_ADDRESS}`;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
      <Button text="Contactez-moi" href={getAppropriateContact()} />
    </div>
  );
}
