import React from 'react';
import { GlassBlock } from '../GlassBlock/GlassBlock';

import { IMAGE_WIDTH } from '../../constants';

// TODO mettre bouton "en savoir plus et un param de lien"

interface ProductCardProps {
  title: string;
  image?: string;
  description: string;
}

export const ProductCard: React.FC<ProductCardProps> = ({ title, image, description }) => {
  return (
    <GlassBlock>
      <figure className="image-figure">
        <figcaption><strong>{title}</strong></figcaption>
        <img src={image} alt={title} width={IMAGE_WIDTH + "px"} />
      </figure>
      {description && <p>{description}</p>}
    </GlassBlock>
  );
};