import { Link } from 'react-router-dom';
import '../../App.css';
import './Approach.css';

import { ContactMe } from '../../components/ContactMe';
import { Button } from '../../components/Button';
import { GlassBlockText } from '../../components/GlassBlock/GlassBlockText';
import { IllustrationBlock } from './IllustrationBlock';


interface Illustration {
  title: string;
  text: string;
  examples: string[];
}

export const Approach = () => {

  const rules: string[] = [
    "Votre objectif est le mien 🎯",
    "Je m'adapte 🔧",
    "Je suis transparente 💯",
    "Je vous pousse vers le progrès 🏆",
    "Je m'engage envers vous 🤝"
  ];

  const illustrations: Illustration[] = [
    {
      title: "Servir mon prochain avant tout : VOUS ! 🎯🔧🤝",
      text: "Quelque soit le cas pour lequel je suis appelée, c'est mon devoir de l'accepter : Tous méritent d'être suivis et profiter d'un suivi de qualité. Bien sûr que j'aime mon activité ainsi que les chiens. Cependant, je n'ai pas honte de dire que j'aime autant l'humain que les animaux voire davantage. Aider son prochain fait partie de mes valeurs. D'ailleurs vous me payez pour des objectifs précis.",
      examples: ["Un chien s'avère trop puissant pour son maître, qui à part cela vit très bien avec lui. Au lieu de lui reprocher qu'il aurait du prendre un chien plus compatible avec son physique et vouloir le replacer, en tant que professionnelle je lui proposerai plusieurs solutions possibles pour améliorer son quotidien ainsi que celui de son chien, lorsque le maître s'engage à les appliquer.",
        "Lorsque malheureusement une situation est critique et que le maître et son entourage ne peuvent attendre trop longtemps, je ferai de mon possible pour trouver une solution rapide (quick fix), tout en ayant conscience qu'elle n'est que temporaire et qu'une meilleure solution devra être mise en place pour rendre les résultats durables. Là je n'ai pas le temps de rigoler, il est question de poursuites en justice, d'accidents, d'abandon voire d'euthanasie !",
        "Ma vie professionnelle à part, je et j'ai fait du bénévolat pour des associations, par exemple en tant que famille d'accueil. En tant que référent de loulou avant tout, je garde également contact avec d'autres personnes que je rencontre lors de mes promenades afin de nous promener ensemble et permettre à nos chiens de rester socialisés."]
    }, {
      title: "Bienveillance et honnêteté 💯🤝",
      text: "Si vous m'avez appelée, c'est pour que je vous forme sur ce que vous ne savez pas (encore), pas pour que je vous accuse sur ce qui est \"mal\". Autrement ce métier n'existerait pas. Néanmoins, je choisis la transparence au discours agréable pour le bien de votre binôme. Soyez conscients que je ne dresse pas votre chien, je vous enseigne. L'importance est d'être prêt à changer ses habitudes et passer à l'action. Le passé derrière, l'avenir devant !",
      examples: ["Je suis appelée pour un husky ingérable en promenade qui ne fait que tirer en laisse et sauter sur tout ce qu'il voit. J'apprends qu'il n'est sorti que 30 minutes par jour. Alors je n'hésiterai pas à dire que cette race a des besoins à combler plus importants qu'un simple chien de compagnie et ce qu'il y a à mettre en place plutôt que simplement dire \"Ce chien n'est pas fait pour vous\"."]
    }, {
      title: "Allier efficacité et durabilité des résultats 🎯🔧🤝💯",
      text: "Parce que j'aime l'efficacité et vous savoir satisfaits, nous trouvons ensemble les méthodes les plus appropriées à chaque cas. Il est donc possible de voir des résultats dès les premières séances et à l'échelle de semaines. D'un autre côté, je ne fais pas l'éloge d'une solution \"fast food\" pour avoir le résultat sur le champ mais avec des effets secondaires derrière : pour résoudre de nombreux problèmes, c'est aux causes qu'il faut s'attaquer ! C'est naturel que vous devez travailler pendant les séances et même après l'accompagnement pour maintenir les résultats.",
      examples: ["Voici une erreur que j'ai faite dans le passé : J'ai mon chien Natto qui dans les temps anciens pouvait gémir à 4h du matin, ce qui était très embêtant. Nous l'ignorons afin de ne pas \"lui donner raison\" de vocaliser et continuons à dormir. Un beau matin, nous retrouvons des selles très molles au sol. Si nous l'avons fait sortir, il n'y aura pas de caca au sol, et si nous évitons les aliments qui ont causé cela, il n'y aura plus de pleurs."]
    }, {
      title: "Un programme sur-mesure 🎯🔧🏆",
      text: "Avec un même chien, l'objectif d'un maître n'est pas le même vis à vis de lui que celui d'un autre maître : Vous me demandez un radiateur, je vous vends un radiateur. Néanmoins, lorsque je détecte un trouble que vous n'avez pas mentionné, nous serons transparents de vous le mentionner, le bien-être du chien étant indispensable à son équilibre en plus de le rendre bien plus réceptif aux bons comportements. Il m'est même possible de changer la séance-même en cours si cela est plus utile !",
      examples: ["Je peux vous proposer une éducation de A à Z comme juste des objectifs précis.",
        "Alors qu'un chien qui tire est généralement gênant, je ne travaille pas le \"problème\" si cela ne vous dérange pas. D'un autre côté, je peux insister sur la notion de seuil (porte, voiture, trottoir) si vous voulez un chien qui attende votre signal avant d'y aller.",
        "Même si un chien qui vous suit partout est mignon, il peut dans certains cas souffrir du trouble de l'anxiété de séparation. Les conséquences ne sont pas forcément gênantes pour le maître si le chien ne détruit ni ne vocalise. En revanche, s'il dépérit, tourne en rond, et est incapable de manger en votre absence par exemple, cela reste un chien qui souffre (s'il vous suit partout mais n'a aucun symptôme inquiétant, il n'y a pas lieu de s'inquiéter).",
        "Si en cours d'une séance, vous me mentionnez oralement un désir que vous avez et que je ne l'ai pas marqué à travailler sur le papier, cela ne m'empêchera pas de faire une ou des séances pour travailler avec vous dessus. Je préfère cela que de programmer le contenu de plusieurs séances suivantes à l'avance.",
        "Histoire vraie : Pendant une séance-même où j'étais censée travailler un chien qui tire, je m'aperçois qu'il souffre d'hyperattachement, et c'est une cause-même du fait qu'il tire. Je transforme donc ma séance d'éducation en séance contre l'hyperattachement."]
    }, {
      title: "Pas d'idolâtrie d'une méthode 🔧🏆",
      text: "Chaque chien voire binôme étant différent, je ne suis pas d'avis qu'il y ait une méthode universelle pour chaque problème et que les autres sont inefficaces voire à proscrire. Si nous répétons la même chose et qu'il n'y a pas d'évolution du tout, mieux vaut changer d'approche (du moins temporairement si le chien n'y est pas réceptif à ce moment). Bien que ma philosophie se tourne vers ce qu'on appelle la \"méthode positive\", je trouve que cela, et surtout ce terme, porte à confusion et préfère dire ceci : Chez KiffePaws Educ, ce n'est pas une UNE méthode, mais DES méthodes.",
      examples: ["Mon chien Natto n'est pas du tout joueur et est indifférent aux jouets, cependant il est très gourmand et accepte presque n'importe quelle friandise, alors j'exploite à fond cette motivation pour l'éduquer. Il serait dommage selon moi de travailler avec lui en utilisant les méthodes banissant les friandises...",
        "J'ai aussi des cas pas du tout gourmands ni joueurs dans des circonstances données, alors si je ne me base que sur la méthode de la friandise et du jouet, comment vais-je m'en sortir ? Vais-je m'acharner à vouloir \"rendre le chien gourmand\" pour pouvoir utiliser ma méthode préférée (qui est bien ma méthode fétiche !), ou m'efforcer à trouver des méthodes plus adaptées et intelligentes ?",
        "Je n'aurai même pas honte d'utiliser des méthodes très farfelues mais efficaces s'il le faut ! Par exemple, un éducateur a réussi à apprendre à un chien récupéré de maltraitance dit irrécupérable à faire ses besoins autre que sur le carrelage de la salle de bain où il y avait été conditionné à faire avec une méthode bien particulière (je vous lance le défi de trouver la vidéo !)",
        "Je suis forcément formée et inspirée de différents établissements d'éducation et ai l'ambition de diversifier les écoles. Il m'est donc difficile de pouvoir affirmer une phrase comme \"Je suis d'Apollos\". L'importance est de savoir exploiter ses compétences sur le terrain et remplir notre mission d'éducateur comportementaliste canin avant tout, à savoir rendre des vies plus agréables voire les sauver."]
    }, {
      title: "Mais tout de même un principe fondamental ! 💯",
      text: "Même s'il faut être capable d'exploiter toutes les façons possibles pour une situation, je me base tout de même sur un concept clé : le leadership, ce que j'aime appelé l'image du berger. Non on ne peut placer les privilèges du chien au même rang que les nôtres. C'est leur causer du tort ! Un berger, c'est quelqu'un à qui ses brebis font confiance. Il a des devoirs envers elle de protection, d'initiative et d'éducation. C'est lui qui fera le plus grand travail s'il veut changer la situation de ses brebis. L'application de solutions autour de ce concept est un vrai game changer pour tous les cas rencontrés sans exception. Nous obtenons par exemple grâce à cela :",
      examples: ["Des chiens plus calmes et obéissants, sachant mieux se gérer et se concentrant plus facilement.", "Des chiens craintifs plus en confiance.", "Des chiens agressifs plus apaisés et qui chercheront moins le rentre-dedans."]
    },
    {
      title: "Le passé derrière, l'avenir devant 🎯🏆",
      text: "Même si en effet nous ne pouvons changer le passé et que votre situation ne sera jamais \"comme neuf\", si vous prenez un accompagnement, c'est pour avoir des changements, certains radicaux. Si des cas commençant d'une situation désastreuse ont pu finir dans une situation encore meilleure que des cas plus \"normaux\", c'est que c'est possible. Il y a 2 conditions pour permettre cela : Vous devez être prêt à changer des habitudes, mais aussi péter des croyances limitantes, dont celles liées à l'esprit d'état fixé que \"C'est trop tard\", \"C'est sa nature d'être comme ça\" ce que nous allons faire ensemble.",
      examples: ["Mon chien actuel est le premier récupéré de refuge. Il a un âge avancé et a beaucoup souffert par le passé, ce qui fait qu'il est arrivé avec plein de problèmes. Eh bien... En faisant des efforts, j'ai finalement pu en faire un chien plus agréable à vivre, promener et à emmener que mes précédents chiens qui étaient tous pages blanches (et plutôt obéissants).", "Je crois au fait que ceux qui commencent bas ou ont des mésaventures sont capables d'atterir plus haut que ceux qui n'ont pas eu autant de défis, en se donnant les moyens."]
    },
    {
      title: "Engagement réciproque 🏆🤝",
      text: "Il sera attendu de vous une coachabilité à 100% ainsi que du travail régulier entre les séances et à la fin de l'accompagnement, cela pour votre bien et celui de votre loulou : cela serait dommage de répéter plusieurs séances similaires et ne pas voir de progrès. Vous l'aurez bien compris, pour obtenir du changement, une sortie de la zone de confort (tout en respectant votre rythme) est inévitable. De mon côté, je m'engage également à vous délivrer le meilleur. Je bannis les solutions d'évitement, car après tout vous me payez pour résoudre une douleur ! Dans le cas de faire progresser votre chien, et surtout vous, l'action a plus sa place que la théorie. Alors je vous ferai agir. D'ailleurs, mon cours théorique est offert dans n'importe quel de nos contrats !",
      examples: ["Je peux voir des gros progrès chez un binôme, et quelques séances plus tard, les problèmes reviennent. La cause est que le binôme a un peu lâché les nouveaux réflexes et a permis aux anciennes habitudes de revenir. N'oubliez pas que le travail doit être constant, il n'y a pas de baguette magique.", "Si vous ne vous sentez pas confiant de faire telle action, je le ferai moi-même dans un premier temps avant de vous laisser faire, étant convaincue que l'on apprend par l'exemple.",
        "Vous ne m'entendrez jamais dire de faire demi-tour, changer de trottoir ou vous promener dans des lieux déserts pour régler la réactivité d'un chien.",
        "Pour un chien qui ne sait pas rester seul, lui accorder une présence permanente quitte à toujours le faire garder n'est pas une solution selon moi (sauf si vous voulez vivre en permanence avec cette contrainte, même pour 10 minutes de course).",
        "C'est ainsi qu'avec mon approche et en travaillant tous les jours, Uka renommée Dina une chienne que j'avais en famille d'accueil avec de nombreux troubles a été rendue en une superbe chienne en... 1 mois et demi ! J'avoue que je l'ai encouragée à affronter des situations inconfortables tout en gardant son rythme."]
    }
  ]

  return (
    <div className="put-spaces">
      <h1 className="small-h1">Mon approche en tant qu'éducateur comportementaliste canin, la philosophie chez KiffePaws Educ...</h1>
      Ce sont les règles sur lesquelles nous nous basons. Les unes ne vont pas sans les autres.

      <div className="rules-container">
        {rules.map((rule, index) => (
          <GlassBlockText key={index} content={rule} />
        ))}
      </div>

      Pour ceux d'entre vous qui veulent en savoir davantage, ci-dessous des illustrations de ces règles.

      <div className="illustrations-container">
        {illustrations.map((illustration, index) => (
          <IllustrationBlock key={index} title={illustration.title} text={illustration.text} examples={illustration.examples} />
        ))}
      </div>

      {/* <p>D'un autre côté, l'équipe de KiffePaws Educ n'est pas non plus parfaite et apprend tous les jours. Nous prenons à coeur les retours constructifs.</p> */}

      <h3>Vous voulez en savoir plus ? </h3>
      <ContactMe />
      <h3>Pas convaincu ?</h3>
      <Link to="/reviews"><Button text="Regardez les témoignages" /></Link>
    </div>
  );
}