import { Button } from './Button';
import { Menu } from './Menu/Menu';

import { PHONE_NUMBER, PHONE_NUMBER_TEXT, IMAGE_WIDTH } from '../constants';

import '../App.css';

import logo from '../images/logo.png';

export const Header = () => {
  return (
    <div className="App-header put-spaces">
      <div className="header-info">
        <div>
          <img src={logo} title="KiffePaws Educ - Apprenez à kiffer la vie ensemble !" alt="logo" width={IMAGE_WIDTH + "px"} style={{ padding: "5% 0" }} />
          {/* Retrouvez sérénité, et surtout apprenez à kiffer la vie ensemble ! */}
        </div>
        <div>
          Lundi - Samedi : 8h - 20h <br />
          Dimanche : 14h - 20h
        </div>
        <Button text={PHONE_NUMBER_TEXT} href={`tel:${PHONE_NUMBER}`} />
      </div>
      <Menu />
    </div>
  );
}