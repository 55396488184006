import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Header } from './components/Header';
import { Footer } from './components/Footer/Footer';
import { CTABand } from './components/CTABand/CTABand';

import { Welcome } from './pages/Welcome';
import { About } from './pages/About';
import { Services } from './pages/Services/Services';
import { Reviews } from './pages/Reviews';
import { Approach } from './pages/Approach/Approach';

import { LegalNotice } from './pages/LegalNotice';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div >
          <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/services' element={<Services />} />
            <Route path='/about' element={<About />} />
            <Route path='/reviews' element={<Reviews />} />
            <Route path='/approach' element={<Approach />} />

            <Route path='/legalnotice' element={<LegalNotice />} />
          </Routes>
        </div>
        <Footer />
        <CTABand />
      </div>
    </Router>
  );
}

export default App;
