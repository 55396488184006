import '../App.css';

import { PHONE_NUMBER_TEXT, EMAIL_ADDRESS } from '../constants';


export const LegalNotice = () => {

  return (
    <div>
      <h1>Mentions légales</h1>
      <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site KiffePaws Educ l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>

      <h2>1. Editeur du site</h2>
      <p>Nom de l'entreprise : KiffePaws Educ</p>
      <p>Nom du responsable : Julia LU DAC</p>
      <p>Statut juridique : Micro-entreprise</p>
      <p>SIRET : 98135623100028</p>
      <p>Adresse du siège social : 105 Avenue des Marronniers, 78650 BEYNES, France</p>
      <p>Téléphone : {PHONE_NUMBER_TEXT}</p>
      <p>Email : {EMAIL_ADDRESS}</p>
      <p>Directeur de la publication : Julia LU DAC</p>

      <h2>2. Hébergeur du site</h2>
      <p>Nom de l'hébergeur : GreenGeeks</p>
      <p>Adresse de l'hébergeur : 3411 Silverside Rd - Tatnall Building #104 - Wilmington, DE 19810 USA</p>
      <p>Téléphone : +1 310 496-8946</p>
      <p>Email : sales@greengeeks.com</p>
      <p>Site web : www.greengeeks.com</p>

      <h2>3. Propriété intellectuelle</h2>
      <p>L’ensemble des contenus (textes, images, vidéos, logos, etc.) présents sur ce site sont la
        propriété exclusive de KiffePaws Educ ou sont utilisés avec l’autorisation de leurs propriétaires.
        Toute utilisation non autorisée de ces contenus est interdite.
      </p>

      <h2>4. Protection des données personnelles</h2>
      <p>Ce site ne collecte aucune donnée personnelle via des formulaires en ligne ou des cookies.
        Les informations de contact sont utilisées uniquement pour répondre aux demandes des utilisateurs
        et ne sont pas stockées dans une base de données. Pour toute demande relative à vos données personnelles,
        vous pouvez contacter {EMAIL_ADDRESS}.</p>

      <h2>5. Limitation de responsabilité</h2>
      <p>
        Le contenu de ce site est fourni à titre informatif. Julia LU DAC s'efforce d'assurer
        l'exactitude des informations diffusées sur ce site, mais ne saurait garantir leur actualité,
        exhaustivité ou exactitude.
        L'utilisation des informations et contenus disponibles sur l'ensemble du site ne saurait
        en aucun cas engager la responsabilité de l'éditeur.
      </p>

      <h2>6. Absence de Conditions Générales de Vente (CGV) et de Médiation de la Consommation</h2>
      <p>Ce site n'a pas vocation à effectuer des transactions commerciales en ligne.
        Il s'agit d'un site vitrine présentant les services de l'entreprise KiffePaws Educ.
        Les Conditions Générales de Vente (CGV) et les informations relatives à la médiation
        de la consommation sont directement communiquées aux clients lors de la signature des contrats
        de prestation de services, en dehors du site internet. Par conséquent, ces éléments ne sont
        pas inclus dans les présentes mentions légales.
      </p>
    </div>
  );
}