import React from 'react';
import { GlassBlock } from '../GlassBlock/GlassBlock';

import { IMAGE_WIDTH } from '../../constants';

interface ProductItemCardProps {
  title: string;
  image?: string;
  text?: React.ReactNode;
}

export const ProductItemCard: React.FC<ProductItemCardProps> = ({ title, image, text }) => {
  return (
    <GlassBlock>
      <figure className="image-figure">
        <figcaption><strong>{title}</strong></figcaption>
        <img src={image} alt={title} width={IMAGE_WIDTH + "px"} />
      </figure>
      {text}
    </GlassBlock>
  );
};