import "./CTABand.css";

import { PHONE_NUMBER, PHONE_NUMBER_TEXT } from "../../constants";

import movingArrow from '../../images/moving_arrow.gif';

export const CTABand = () => {
  return (
    <div id="cta-band">
      <a href={`tel:${PHONE_NUMBER}`}>
        <span id="cta-text-big"> Contactez-moi ! </span>
        <span id="cta-text-small"> Contactez-moi ! </span>
        <img src={movingArrow} alt="arrow" id="cta-arrow" />
        {PHONE_NUMBER_TEXT}
      </a>
    </div>
  )
};