import React from 'react';
import './GlassBlock.css';

interface GlassBlockProps {
  children: React.ReactNode;
}

export const GlassBlock: React.FC<GlassBlockProps> = ({ children }) => {
  return (
    <div className="glass-block">
      {children}
    </div>
  );
};