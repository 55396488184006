import '../App.css';

import { Button } from './Button';
import { Review } from './Review/Review';
import reviews from '../data/reviews.json';

export const WelcomeReviews = () => {

  return (
    <div>
      <div>
        <h2>Mes clients témoignent</h2>
        {reviews.slice(0, 3).map((review, index) => (
          <Review key={index} lines={review.lines} author={review.author} index={index} photo={`/images/reviews/${review.photo}`} />
        ))}
      </div>
      <div style={{ display: "inline-flex" }}>
        <Button text="Voir tous les avis Google" href="https://g.co/kgs/1wUjVVU" />
        <Button text="Voir tous les avis Facebook" href="https://www.facebook.com/profile.php?id=61556683478682" />
      </div>
    </div>
  );
}