import '../App.css';

import { EMAIL_ADDRESS, FACEBOOK_LINK, INSTAGRAM_LINK } from '../constants';
import mail from '../images/mail.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';


export const SocialLinks = () => {
  return (
    <div className="App-social">
      <a href={`mailto:${EMAIL_ADDRESS}`} ><img src={mail} width="32" alt="mail" /></a>
      <a href={FACEBOOK_LINK} ><img src={facebook} width="32" alt="facebook" /></a>
      <a href={INSTAGRAM_LINK} ><img src={instagram} width="32" alt="instagram" /></a>
    </div>
  );
}