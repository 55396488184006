import './Goals.css';

import { Card } from '../GoalCard/Card';
import { ContactMe } from '../ContactMe';

import tire from '../../images/pains/tire_small.png';
import saute from '../../images/pains/saute_small.png';
import detruit from '../../images/pains/detruit_small.png';
import malpropre from '../../images/pains/malpropre_small.png';
import vole from '../../images/pains/vole_small.png';
import aboie from '../../images/pains/aboie_small.png';
import fugue from '../../images/pains/fugue_small.png';
import agressif from '../../images/pains/agressif_small.png';
import craintif from '../../images/pains/craintif_small.png';
import anxiete from '../../images/pains/anxiete_small.png';
import rebelle from '../../images/pains/rebelle_small.png';

import content from '../../images/wishes/content_small.png';
import calme from '../../images/wishes/thalyaAttente_small.png';
import enLaisse from '../../images/wishes/enLaisse_small.png';
import lacher from '../../images/wishes/lacher_small.png';
import partout from '../../images/wishes/hulkVoiture_small.png';
import complicite from '../../images/wishes/complicite_small.png';

export const Goals = () => {

  const wishes: string[][] = [
    ["Avoir un chien bien dans ses pattes 😄", content, "chien heureux"],
    ["Avoir un chien patient 🧘", calme, "chien sur tapis"],
    ["Promener sereinement votre chien en laisse 🤝", enLaisse, "chien en laisse"],
    ["Lâcher votre chien en toute circonstance 🦅", lacher, "plusieurs chiens en promenade"],
    ["Emmener votre chien partout 🚗", partout, "chien dans la voiture"],
    ["Avoir une superbe complicité ensemble, tout simplement ❤️", complicite, "chien complice chien heureux"],
  ];

  const pains: string[][] = [
    ["tire en laisse 🎿", tire, "mon chien tire en laisse"],
    ["saute 🦘", saute, "mon chien saute"],
    ["détruit 💣", detruit, "mon chien détruit tout"],
    ["est malpropre 🚽", malpropre, "mon chien est malpropre"],
    ["vole 🦝", vole, "mon chien vole"],
    ["aboie intempestivement 🎷", aboie, "mon chien aboie"],
    ["fugue 🥷", fugue, "mon chien fugue"],
    ["est agressif 😡", agressif, "mon chien est agressif ou réactif"],
    ["est craintif 🫣", craintif, "mon chien est craintif, il a peur de tout"],
    ["a de l'anxiété de séparation 😭", anxiete, "mon chien n'aime pas être seul"],
    ["est simplement 'rebelle' 🙅", rebelle, "mon chien n'écoute rien"]
  ];

  return (
    <div className="App-goals put-spaces">
      <div className="App-pains">
        <h2>Votre chien...</h2>
        <div className="figure-container">
          {pains.map((pain: string[], index: number) => <Card key={index} name={pain[0]} title={pain[2]} image={pain[1]} />)}
        </div>
        <ContactMe />
      </div>
      <div className="App-wishes">
        <h2>Vous souhaitez...</h2>
        <div className="figure-container">
          {wishes.map((wish: string[], index: number) => <Card key={index} name={wish[0]} title={wish[2]} image={wish[1]} />)}
        </div>
        <ContactMe />
      </div>
      <div>
      </div>
      <div>
        <p>Malgré le fait que vous l'aimiez, vous le subissez que ce soit à la maison, à l'extérieur voire les 2. Lui ainsi que votre relation avec votre entourage, votre santé et votre argent souffrez de la situation...</p>
        <p>Je suis là pour accompagner votre binôme afin de vous permettre de retrouver sérénité, fun et complicité.</p>
        <p>Pour plus d'informations, n'hésitez pas :</p>
        <ContactMe />
      </div>
    </div>
  );
}