import '../../App.css';
import './Services.css';
import { ProductCard } from '../../components/ProductCard/ProductCard';
import { ProductItemCard } from '../../components/ProductCard/ProductItemCard';

import educationCardPicture from "../../images/products/training/siriusMAP_small.png";
import therapieCardPicture from "../../images/products/training/ukaDestructions_small.png";
import promenadeEducCardPicture from "../../images/products/training/promEducPlaisir_small.png";
import promenadeCardPicture from "../../images/products/sitting/promenade_small.png";
import gardeDomCardPicture from "../../images/products/sitting/gardeDomicile_small.png";
import gardeCardPicture from "../../images/products/sitting/garde_small.png";

import edcPicture from "../../images/products/training/edc_small.png";
import seanceTeaserPicture from "../../images/products/training/seanceTeaser_small.png";
import seanceIndivPicture from "../../images/products/training/seanceIndiv_small.png";
import seanceCoPicture from "../../images/products/training/seanceCo_small.png";

export const Services = () => {

  return (
    <div className="put-spaces">
      <h1 className="small-h1">Je vous présente mes solutions adaptées pour éduquer votre chien ou le faire garder en toute sérénité</h1>

      <h2>Education canine et travail sur le comportement</h2>
      <div className="products-container">
        <ProductCard title="Education"
          image={educationCardPicture}
          description='Posez des bases solides, renforcez les comportements adaptés et débarrassez-vous des "petits tracas".'
        />
        <ProductCard title="Thérapie comportementale"
          image={therapieCardPicture}
          description='Aussi appelée rééducation. Aidez votre chien à surmonter ses peurs, son agressivité, son angoisse de la séparation et autres troubles bien ancrés.'
        />
        <ProductCard title="Promenades éducatives"
          image={promenadeEducCardPicture}
          description="Profitez d'une promenade avec d'autres binômes tout profitant d'un léger coaching."
        />
      </div>

      <h2>Services de pet sitting</h2>
      <div className="products-container">
        <ProductCard title="Promenade de votre chien"
          image={promenadeCardPicture}
          description="Vous ne pouvez pas le promener ? Je m'en occupe !"
        />
        <ProductCard title="Garde à votre domicile"
          image={gardeDomCardPicture}
          description="Profitez tranquillement de vos vacances tandis que votre chien a de la visite."
        />
        <ProductCard title="Garde à mon domicile"
          image={gardeCardPicture}
          description="Proposez à votre chien la colonie de vacances pendant que vous profitez !"
        />
      </div>

      <h1 className="small-h1">Les étapes d'un accompagnement d'éducation et/ou rééducation</h1>
      Que vous m'appeliez pour des problèmes de comportement ou de l'éducation, les étapes sont les mêmes. Aussi, tout pourra être traité dans le même accompagnement je vous l'assure !
      <div className="product-items-container">
        <ProductItemCard title="#1 Bilan comportemental (étude de comportement)"
          image={edcPicture}
          text=
          {<><p>Avant de programmer les séances, une <strong>étude de comportement </strong> (50€) à domicile s'avère nécessaire afin de mieux identifier vos besoins ainsi que de mieux connaître votre chien dans ses habitudes.
            Pour cela, j'analyserai votre mode de vie avec loulou et vous poserai des questions détaillées. En effet, il faut commencer par "connaître le terrain" pour mieux réussir ! 🙂
          </p>
            <p>
              Grâce à la synthèse obtenue à la fin de ce bilan comportemental, j'estimerai le nombre de séances nécessaires à vos attentes et vous ferai un <strong>devis personnalisé 🔧</strong>.
            </p></>}
        />
        <ProductItemCard title="#2 Séance bienvenue 🎁 - aussi appelée séance teaser"
          image={seanceTeaserPicture}
          text={<>
            <p>L’objectif de cette séance est de pouvoir vous expliquer les piliers fondamentaux (dont les 3 principaux) pour obtenir davantage (ou maintenir) un chien équilibré et complice, mais aussi traiter théoriquement tous les problèmes que vous m'avez mentionnés lors du bilan comportemental.</p>
            <p>Vous aurez en complément des démonstrations live que vous pourrez appliquer dès que possible à la fin de la séance. Cette séance vous apportera beaucoup en plus d'être automatiquement incluse dans un contrat d'accompagnement. Génial non ?
            </p>
          </>}
        />
        <ProductItemCard title="#3 Séances individuelles"
          image={seanceIndivPicture}
          text={<>
            <p>
              De manière générale, les premières séances que nous faisons ensemble sont individuelles : Avoir <strong>posé les bases</strong> le cas échéant après, nous ciblons immédiatement les grosses problématiques et permettons à des premiers résultats de pointer le bout du nez (dès les premières séances).
            </p>
            <p>Egalement, j'apprends à mieux connaître votre binôme et par conséquent <strong>adapter mon accompagnement envers votre chien et vous</strong>. Exemple parmi d'autres, il me sera possible de savoir ce qui motive le plus loulou. Un ventre sur patte ? Un fan de la balle ? Un mendiant d'appréciations ? Il ne peut pas résister aux odeurs ? etc. </p>
            <p>Suivant ce qu'il faut travailler, <strong>une séance peut tout à fait se dérouler à domicile comme en extérieur</strong>... comme en centre commercial, comme en restaurant (quand on parle d'adaptation à votre besoin !)</p>
            <p>Suivant ce qu'il faut travailler + le niveau de votre binôme, la séance suivante sera soit individuelle soit collective.</p>
          </>}
        />
        <ProductItemCard title="#4 Séances collectives"
          image={seanceCoPicture}
          text={<>
            <p>Les séances collectives vous permettent de gérer votre chien dans les meilleures conditions <strong>en ajoutant du stimuli et en vous donnant plus d'autonomie</strong>.</p>
            <p>Je vous en proposerai lorsque c'est dans le meilleur intérêt de votre chien et de vous : En effet, pour certains de vos objectifs et selon le niveau de votre binôme, les séances individuelles sont plus adaptées et pour d'autres, les séances collectives sont plus bénéfiques.</p>
            <p>Les cas sont donc <strong>adaptés</strong> afin de permettre une progression optimale.</p>
          </>}
        />
      </div>
    </div>
  );
}