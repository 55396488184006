import '../App.css';

export const DeservedAreas = () => {

  const villes: string[] = [
    "Versailles",
    "Buc",
    "Poissy",
    "Le Chesnay-Rocquencourt",
    "Bois d'Arcy",
    "Guyancourt",
    "Montigny-Le-Bretonneux",
    "Trappes",
    "Maurepas",
    "Coignières",
    "Elancourt",
    "Villepreux",
    "Les Clayes-Sous-Bois",
    "Plaisir",
    "Jouars-Pontchartrain",
    "Villiers-Saint-Frédéric",
    "Beynes",
    "Maule",
    "Thoiry",
    "Montfort-l'Amaury",
    "Méré",
    "Chevreuse",
    "Rambouillet",
    "Houdan",
    "Saint-Germain-En-Laye",
    "Meudon",
    "Clamart",
    "Suresnes",
    "Issy-Les-Moulineaux",
  ]
  const villesListing: string = villes.join(", ");

  return (
    <div className="put-spaces">
      <h2>Zones d'intervention</h2>
      <p>J'interviens principalement en Yvelines (78) et en Hauts-de-Seine (92) et ce sans frais kilométrique.
        Voici une liste <strong>non exhaustive</strong> de villes où je me déplace :
      </p>
      <p>{villesListing}</p>
      <p>Une intervention en-dehors de ces départements est envisageable au cas par cas.</p>
    </div>
  );
}